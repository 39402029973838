import { Field } from "formik"
import { Form } from "react-bootstrap"
import { checkVal } from "../../../Helpers/Parsers/checkVal"


export const CheckBoxInputField = (props: {
  fieldLabel: string,
  fieldName: string,
  customClass?: string,
  touched?: any,
  errors?: any,
  required?: boolean,
  subText?: string,
  onChange?: (e: any) => void,
  disabled?: boolean,
}) => {
  const {
    fieldLabel,
    fieldName,
    customClass,
    subText,
    onChange,
    // required
    disabled = false
  } = props
  return (
    <>
    <Field type="checkbox" name={fieldName}>
      {({
        field: {
          value
        },
        form
      }: any) => {
        return (
        <Form.Group className={`mb-3 ${customClass ? customClass : ''}`}>
          {/*<Form.Label>{fieldLabel}{required ? '*' : ''}</Form.Label>*/}
          <Form.Check
            type={'checkbox'}
            label={fieldLabel}
            name={fieldName}
            disabled={disabled}
            onChange={(e: any) => {
              if(onChange) {
                onChange(e)
              }
              form.handleChange(e)
            }}
            onBlur={form.handleBlur}
            value={value}
            checked={value}
            // id={`default-${fieldName}`}
            // required={required}
            // isInvalid={checkVal(form.touched, fieldName) && checkVal(form.errors, fieldName)}
            // isValid={!checkVal(form.errors, fieldName) && checkVal(form.touched, fieldName)}
            />
            {subText && (
              <Form.Text className="text-muted">
                {subText}
              </Form.Text>
            )}
            <Form.Control.Feedback type="invalid">
              {checkVal(form.errors, fieldName)}
            </Form.Control.Feedback>
      </Form.Group>
      )
    }}
    </Field>

    {/*<div className={`field-container checkbox-input-container ${customClass ? customClass : ''}`}>
      <label htmlFor={fieldName} className="check-box">
        <Field type="checkbox" name={fieldName} />
        {fieldLabel}
      </label>
      <ErrorMessage name={fieldName} render={ErrorComponent} />
    </div>*/}
    </>
  )
}
