import { ErrorMessage, Form, Formik } from "formik";
import { useState } from "react";
import { Button } from "react-bootstrap";
import ImageUploadField from "../../FormFields/ImageUpload/ImageUpload";
import { SelectInputField } from "../../FormFields/SelectInput/SelectInputField";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";
import { editUserSchema, userFieldRoles } from "./EditUserFormHelpers";
import canAccess from "../../../services/accessService/accessService";
import { PhoneNumberInputField } from "../../FormFields/PhoneNumberInput/PhoneNumberInputField";
import { CheckBoxInputField } from "../../FormFields/CheckBoxInput/CheckBoxInputField";
import { error } from "console";

export function EditUserForm(props: {
  firstName?: string,
  lastName?: string,
  profilePic?: string,
  email?: string,
  phone?: string,
  role?: string,
  notificationSettings?: {
    whatsApp: boolean,
    email: boolean,
    sms: boolean,
  },
  disabled: boolean,
  onSubmit: (profileInfo: {
    firstName?: string,
    lastName?: string,
    profilePic?: string,
    email?: string,
    role?: string,

  }) => Promise<void>,
  uploadImageFn: (imageInfo: { imageToUpload: any, filename: string }) => Promise<string>,
}) {
  const {
    firstName,
    lastName,
    profilePic,
    email,
    phone,
    role,
    disabled,
    onSubmit,
    uploadImageFn,
    notificationSettings = {
      whatsApp: false,
      email: false,
      sms: false,
    }
  } = props
  const [uploadingImage, setUploadingImage] = useState(false)

  return (
    <div>
      <h2>Edit User</h2>
      <Formik
        initialValues={{
          firstName,
          lastName,
          email,
          phone,
          profilePic,
          role,
          password: '',
          notificationSettings: {
            whatsApp: notificationSettings.whatsApp,
            email: notificationSettings.email,
            sms: notificationSettings.sms,
          }
        }}
        validationSchema={editUserSchema}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const submissionValues: any = {
              firstName: values.firstName,
              lastName: values.lastName,
              profilePic: values.profilePic,
              phone: values.phone,
              role: values.role,
              email: values.email ? values.email.toLowerCase() : values.email,
              notificationSettings: {
                whatsApp: values.notificationSettings.whatsApp && values.phone ? true : false,
                email: values.notificationSettings.email,
                sms: values.notificationSettings.sms && values.phone ? true : false,
              }
            }
            if (values.password && values.password.length > 5) {
              submissionValues.password = values.password
            }
            await onSubmit(submissionValues)
            setSubmitting(false)

          } catch (err) {
            // custom error handler
          }
          // setSubmitting(false)
        }}
      >

        {({ isSubmitting, setFieldValue, touched, errors, values }) => (
          <Form>
            <TextInputField
              fieldLabel="First Name"
              placeholder="First Name"
              required={true}
              fieldName="firstName" />

            <TextInputField
              fieldLabel="Last Name"
              placeholder="Last Name"
              required={true}
              fieldName="lastName" />

            <TextInputField
              fieldLabel="Email"
              placeholder="Email"
              fieldType="email"
              required={true}
              fieldName="email" />

            <PhoneNumberInputField
              fieldName="phone"
              fieldLabel="Phone"
            />

            <SelectInputField
              fieldLabel="Role"
              placeholder="- Select Role -"
              required={true}
              fieldName="role"
              options={userFieldRoles()} />


            <hr />
            <h3>Notification Settings</h3>
            <CheckBoxInputField
              fieldName="notificationSettings.whatsApp"
              fieldLabel="WhatsApp Notifications"
              disabled={values.phone && !errors.phone ? false : true}
            />

            <CheckBoxInputField
              fieldName="notificationSettings.email"
              fieldLabel="Email Notifications"
            />

            <CheckBoxInputField
              fieldName="notificationSettings.sms"
              fieldLabel="SMS Notifications"
              disabled={values.phone && !errors.phone ? false : true}
            />


            <hr />


            {canAccess('user', 'form.changePassword') && (
              <TextInputField
                fieldLabel="Change Password"
                placeholder="*****"
                fieldType="password"
                fieldName="password" />
            )}

            {values.profilePic && (
              <>
                <img alt={'profile pic preview'} src={values.profilePic} className="profile-pic-preview" />
                <br />
              </>
            )}
            <ImageUploadField
              uploadedImageCallback={async (imageInfo: any) => {
                setUploadingImage(true)
                try {
                  const uploadedImageUrl = await uploadImageFn(imageInfo)
                  if (uploadedImageUrl) {
                    setFieldValue('profilePic', uploadedImageUrl)
                  }
                } catch (err) {
                  // something went wrong
                }
                setUploadingImage(false)
              }}
            />
            <ErrorMessage name="profilePic" component="div" />
            <br />

            <Button className="mb-3" type="submit" disabled={disabled || isSubmitting || uploadingImage || Object.keys(errors).length > 0}>
              Update
            </Button>
          </Form>
        )}
      </Formik>

    </div>
  )
}
