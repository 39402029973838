import { ErrorMessage, Form, Formik } from "formik";
import { useState } from "react";
import { Button } from "react-bootstrap";
import ImageUploadField from "../../FormFields/ImageUpload/ImageUpload";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";
import { PhoneNumberInputField, phoneValidationYup } from "../../FormFields/PhoneNumberInput/PhoneNumberInputField";
import * as yup from 'yup'

export const editUserSchema = yup.object().shape({
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  // email: yup.string().email().required(),
  phone: phoneValidationYup('phone'),
  profilePic: yup.string().nullable(),
})

export function EditProfileForm(props: {
  firstName?: string,
  lastName?: string,
  phone?: string,
  profilePic?: string,
  disabled: boolean,
  onSubmit: (profileInfo: { firstName?: string, lastName?: string, phone?: string, profilePic?: string }) => Promise<void>,
  uploadImageFn: (imageInfo: { imageToUpload: any, filename: string }) => Promise<string>,
}) {
  const { firstName, lastName, profilePic, phone, disabled, onSubmit, uploadImageFn } = props
  const [uploadingImage, setUploadingImage] = useState(false)
  return (
    <div>
      <h2>Edit Profile</h2>
      <Formik
        initialValues={{ firstName, lastName, phone, profilePic }}
        // validate={values => {
        //   const errors: any = {};
        //   if (!values.firstName) {
        //     errors.firstName = 'Required';
        //   }
        //   if (!values.lastName) {
        //     errors.lastName = 'Required';
        //   }
        //   return errors;
        // }}
        validationSchema={editUserSchema}

        onSubmit={async (values, { setSubmitting }) => {
          try {
            await onSubmit({
              firstName: values.firstName,
              lastName: values.lastName,
              phone: values.phone,
              profilePic: values.profilePic,
            })
            setSubmitting(false)

          } catch (err) {
            // custom error handler
          }
          // setSubmitting(false)
        }}
      >

        {({ isSubmitting, setFieldValue, touched, errors, values }) => (
          <Form>
            <TextInputField
              fieldLabel="First Name"
              placeholder="First Name"
              required={true}
              fieldName="firstName" />

            <TextInputField
              fieldLabel="Last Name"
              placeholder="Last Name"
              required={true}
              fieldName="lastName" />


            <PhoneNumberInputField
              fieldLabel="Phone"
              placeholder="Phone"
              fieldName="phone"
            />

            {/*<Field type="text" placeholder="profilPic" name="profilePic" /><br />
            <ErrorMessage name="profilePic" component="div" />*/}
            {values.profilePic && (
              <>
                <img alt={'profile pic preview'} src={values.profilePic} className="profile-pic-preview" />
                <br />
              </>
            )}
            <ImageUploadField
              uploadedImageCallback={async (imageInfo: any) => {
                setUploadingImage(true)
                try {
                  const uploadedImageUrl = await uploadImageFn(imageInfo)
                  if (uploadedImageUrl) {
                    setFieldValue('profilePic', uploadedImageUrl)
                  }
                } catch (err) {
                  // something went wrong
                }
                setUploadingImage(false)
              }}
            />
            <br />
            <ErrorMessage name="profilePic" component="div" />
            <br />
            <Button className="mb-3" type="submit" disabled={disabled || isSubmitting || uploadingImage || Object.keys(errors).length > 0}>
              Update
            </Button>
          </Form>
        )}
      </Formik>

    </div>
  )
}
