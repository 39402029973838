import { Form, Formik } from "formik";
import { useState } from "react";
import { ArrayFieldInput } from "../../FormFields/ArrayInput/ArrayFieldInput";
import { CheckBoxInputField } from "../../FormFields/CheckBoxInput/CheckBoxInputField";
import ImageUploadField from "../../FormFields/ImageUpload/ImageUpload";
import { TextInputField } from "../../FormFields/TextInput/TextInputField";
import { PropertyType } from "../../../Types/propertyTypes";
import './propertyForm.scss'
import { blankProperty, propertySchema } from "./PropertyFormHelpers";
import {
  aboutSectionsFieldValues,
  aliasFieldValues,
  authorizedUsersFieldValues,
  calendarFieldValues,
  extraFeesFieldValues,
  listedFieldValues
} from "./PropertyFormConstants";
import { Button, Col, Row } from "react-bootstrap";
import Collapsible from "../../Collapsible/Collapsible";
import { modImageName } from "../../FormFields/ImageUpload/imageUploadHelpers";
import { imageGalleryFieldValues, linkedServicesFieldValues } from "../commonFieldValues";
import AddressInputField from "../../FormFields/AddressInput/AddressInputField";
import { DateInputField } from "../../FormFields/DateInput/DateInputField";
import { PhoneNumberInputField } from "../../FormFields/PhoneNumberInput/PhoneNumberInputField";

export const PropertyForm = (props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  uploadImageFn: (v: any) => Promise<string>,
  uploadImageMultiFn: (v: { imageToUpload: any, filename: string }[]) => Promise<string[]>,
  imageUploadProgress: string,
  initialValues?: Partial<PropertyType>,
  propertyOptionList: { value: string, text: string }[]
}) => {

  const {
    disabled,
    onSubmit,
    uploadImageFn,
    uploadImageMultiFn,
    imageUploadProgress,
    initialValues,
    propertyOptionList
  } = props

  const [uploadingImage, setUploadingImage] = useState(false)

  return (
    <div className="property-form">
      <Formik
        initialValues={{
          ...blankProperty(),
          ...(initialValues ? initialValues : {})
        }}
        validationSchema={propertySchema}
        // validate={values => { const errors: any = {}; return errors; }}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const submissionResponse = await onSubmit(values)
            if (!submissionResponse.error) {
              return
            }
          } catch (err) {
            // custom error handler
          }
          setSubmitting(false)
        }}
      >
        {({ values, errors, touched, setFieldValue, isSubmitting }) => {

          let eligibleForEarlyCheckIn = true
          let eligibleForLateCheckOut = true
          let eligibleForFontainePortal = values?.title?.toLowerCase().indexOf('fontaine') > -1
          let eligibleForParking = true
          let eligibleForRemoteLock = true

          values?.linkedServices?.forEach((linkedService: any) => {
            if (linkedService.linkedService === 'earlyCheckIn') {
              eligibleForEarlyCheckIn = false
            }
            if (linkedService.linkedService === 'lateCheckOut') {
              eligibleForLateCheckOut = false
            }
            if (linkedService.linkedService === 'fontainePortal') {
              eligibleForFontainePortal = false
            }
            if (linkedService.linkedService === 'parking') {
              eligibleForParking = false
            }
            if (linkedService.linkedService === 'remoteLock') {
              eligibleForRemoteLock = false
            }
          })

          return (
            <Form>

              <h3>General Info</h3>
              <TextInputField
                fieldLabel="Title"
                placeholder="title"
                required={true}
                fieldName="title" />

              <TextInputField
                fieldLabel="Description"
                placeholder="Description"
                required={true}
                fieldType={'textarea'}
                fieldName="description" />


              {/* <h3>Property Aliases</h3> */}
              <Collapsible
                title="Property Aliases"
                TitleTag="h3"
              >
                <div>
                  <label htmlFor="Aliases">Aliases</label><br />
                  <ArrayFieldInput
                    fieldName={'aliases'}
                    values={values.aliases}
                    setFieldValue={setFieldValue}
                    blankEntryFn={() => ({ alias: '', aliasSource: '' })}
                    fields={aliasFieldValues} />
                </div>
              </Collapsible>

              {/* <h3>Owner Information</h3> */}
              <Collapsible
                title="Owner Information"
                TitleTag="h3"
              >
                <div>
                  <Row>
                    <Col xs={12} md={6}>
                      <TextInputField
                        fieldLabel="Owner First Name"
                        placeholder="Owner First Name"
                        fieldName={`owner.firstName`} />
                    </Col>
                    <Col xs={12} md={6}>
                      <TextInputField
                        fieldLabel="Owner Last Name"
                        placeholder="Owner Last Name"
                        fieldName={`owner.lastName`} />
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} md={6}>
                      <PhoneNumberInputField
                        fieldLabel="Owner Phone"
                        placeholder="Owner Phone"
                        fieldName={`owner.phone`} />
                    </Col>
                    <Col xs={12} md={6}>
                      <TextInputField
                        fieldLabel="Owner Email"
                        placeholder="Owner Email"
                        fieldName={`owner.email`} />
                    </Col>
                  </Row>
                </div>
              </Collapsible>


              <h3>Property Address</h3>
              <AddressInputField
                fieldName={'address'}
                required={true}
              />


              <h3>Featured Image</h3>
              {values.featuredImage && (
                <>
                  <img alt={'featured preview'} src={values.featuredImage} className="image-preview" />
                  <br />
                </>
              )}
              <TextInputField
                fieldLabel="Featured Image"
                placeholder="Featured Image"
                fieldName={`featuredImage`} />

              <TextInputField
                fieldLabel="Thumbnail Image"
                placeholder="Thumbnail Image"
                fieldName={`thumbnailImage`} />

              <ImageUploadField
                key={'featuredImageUpload'}
                uploadedImageCallback={async (imageInfo: any, thumbInfo: any) => {
                  setUploadingImage(true)
                  try {
                    const uploadedImageUrl = await uploadImageFn(imageInfo)
                    const uploadedThumbUrl = await uploadImageFn({
                      ...thumbInfo,
                      filename: modImageName(thumbInfo.filename, '_thumb')
                    })
                    if (uploadedImageUrl) {
                      setFieldValue('featuredImage', uploadedImageUrl)
                      setFieldValue('thumbnailImage', uploadedThumbUrl)
                    }
                  } catch (err) {
                    // something went wrong
                  }
                  setUploadingImage(false)
                }}
              />
              <br />
              {/*<ErrorMessage name="featuredImage" component="div" />*/}

              {/* <h3>PropertyImages</h3> */}
              <Collapsible
                title="Property Images"
                TitleTag="h3"
              >
                <div>
                  <label htmlFor="Image Gallery">Image Gallery</label><br />
                  <ArrayFieldInput
                    fieldName={'imageGallery'}
                    customClass={'image-gallery-drag-container'}
                    values={values.imageGallery}
                    setFieldValue={setFieldValue}
                    blankEntryFn={() => ({ url: '', title: '', description: '', thumbUrl: '' })}
                    imgPreview={{
                      srcVal: 'url'
                    }}
                    fields={imageGalleryFieldValues} />
                  {imageUploadProgress && (<>{imageUploadProgress} <br /></>)}
                  <ImageUploadField
                    key={'imageGalleryUpload'}
                    multipleImages={true}
                    uploadedImageCallback={async (imagesInfo: any[], thumbsInfo: any[]) => {
                      setUploadingImage(true)
                      try {
                        const uploadedImageList = await uploadImageMultiFn(imagesInfo)
                        const uploadedThumbs = await uploadImageMultiFn(thumbsInfo.map(x => {
                          return {
                            ...x,
                            filename: modImageName(x.filename, '_thumb')
                          }
                        }))
                        if (uploadedImageList) {
                          const mappedUploadedImages = uploadedImageList.map((imgUrl: string, idx: number) => {
                            const thumbUrl = uploadedThumbs && uploadedThumbs[idx] ? uploadedThumbs[idx] : ''
                            return {
                              url: imgUrl,
                              title: '',
                              description: '',
                              thumbUrl
                            }
                          })
                          setFieldValue('imageGallery', [...values.imageGallery, ...mappedUploadedImages])
                        }
                      } catch (err) {
                        // something went wrong
                      }
                      setUploadingImage(false)
                    }}
                  />
                </div>
              </Collapsible>

              {/* <h3>Calendars</h3> */}
              <Collapsible
                title="Calendars"
                TitleTag="h3"
              >
                <div>
                  <label htmlFor="Calendars">Calendars</label><br />
                  <ArrayFieldInput
                    fieldName={'calendars'}
                    values={values.calendars}
                    setFieldValue={setFieldValue}
                    blankEntryFn={() => ({ url: '', source: '' })}
                    fields={calendarFieldValues} />
                </div>
              </Collapsible>


              {/* <h3>Listed</h3> */}
              <Collapsible
                title="Listed"
                TitleTag="h3"
              >
                <div>
                  <label htmlFor="Listed">Listed</label><br />
                  <ArrayFieldInput
                    fieldName={'listed'}
                    values={values.listed}
                    setFieldValue={setFieldValue}
                    blankEntryFn={() => ({ url: '', source: '', internalName: '', internalTitle: '' })}
                    fields={listedFieldValues} />
                </div>
              </Collapsible>

              <h3>Property Details</h3>
              <TextInputField
                fieldLabel="Summary"
                placeholder="Summary"
                fieldType={'textarea'}
                fieldName={`about.summary`} />

              <TextInputField
                fieldType="number"
                fieldLabel="Guests"
                required={true}
                placeholder="Guests"
                fieldName={`about.guests`} />

              <h4>Guest Breakdown</h4>
              <Row>
                <Col xs={12} md={4}>
                  <TextInputField
                    fieldType="number"
                    fieldLabel="Adults"
                    placeholder="Adults"
                    fieldName={`about.guestInfo.adults`} />
                </Col>
                <Col xs={12} md={4}>
                  <TextInputField
                    fieldType="number"
                    fieldLabel="Children"
                    placeholder="Children"
                    fieldName={`about.guestInfo.children`} />
                </Col>
                <Col xs={12} md={4}>
                  <TextInputField
                    fieldType="number"
                    fieldLabel="Infants"
                    placeholder="Infants"
                    fieldName={`about.guestInfo.infants`} />
                </Col>
              </Row>



              <h4>Rooms and Baths</h4>

              <Row>
                <Col xs={12} md={4}>
                  <TextInputField
                    fieldType="number"
                    fieldLabel="Bedrooms"
                    required={true}
                    placeholder="Bedrooms"
                    fieldName={`about.bedrooms`} />
                </Col>
                <Col xs={12} md={4}>
                  <TextInputField
                    fieldType="number"
                    fieldLabel="Beds"
                    required={true}
                    placeholder="Beds"
                    fieldName={`about.beds`} />
                </Col>
                <Col xs={12} md={4}>
                  <TextInputField
                    fieldType="number"
                    fieldLabel="Other Beds"
                    required={true}
                    placeholder="Other Beds (sofabeds, hammocks, etc...)"
                    fieldName={`about.otherBeds`} />
                </Col>
              </Row>

              <Row>
                <Col xs={12} md={4}>
                  <TextInputField
                    fieldType="number"
                    fieldLabel="Bathrooms"
                    required={true}
                    placeholder="Bathrooms"
                    fieldName={`about.bathrooms`} />

                </Col>
                <Col xs={12} md={4}>
                  <TextInputField
                    fieldType="number"
                    fieldLabel="Baths"
                    required={true}
                    placeholder="Baths"
                    fieldName={`about.baths`} />
                </Col>
                <Col xs={12} md={4}>

                  <TextInputField
                    fieldType="number"
                    fieldLabel="Half Bathrooms"
                    required={true}
                    placeholder="Half Bathrooms"
                    fieldName={`about.halfBathrooms`} />
                </Col>
              </Row>




              <label htmlFor="Amenities">Amenities</label><br />
              UPDATE AMENITIES COMPONENT<br /><br />
              {/*<SimpleArrayFieldInput
              fieldName={'about.amenities'}
              values={values.about.amenities}
              possibleValues={[
                { value: 'bbq', text: 'BBQ' },
                { value: 'microwave', text: 'Microwave' },
                { value: 'heatedTub', text: 'Heated Tub' },
                { value: 'poolTable', text: 'Pool Table' },
              ]}
            /><br />*/}
              {/* <h4>Description Sections</h4> */}
              <Collapsible
                title="Description Sections"
                TitleTag="h4"
              >
                <div>
                  <label htmlFor="Description Sections">Description Sections</label><br />
                  <ArrayFieldInput
                    fieldName={'about.sections'}
                    values={values.about.sections}
                    setFieldValue={setFieldValue}
                    blankEntryFn={() => ({ sectionTitle: '', content: '' })}
                    fields={aboutSectionsFieldValues} />
                </div>
              </Collapsible>

              <h3>Multi Unit</h3>
              <CheckBoxInputField
                fieldLabel={'Is this a multi unit?'}
                fieldName={'multiUnit.isMultiUnit'} />

              {values.multiUnit.isMultiUnit && (
                <>
                  <label htmlFor="Linked Units">Linked Units</label><br />
                  <ArrayFieldInput
                    fieldName={'multiUnit.linkedUnits'}
                    values={values.multiUnit.linkedUnits}
                    setFieldValue={setFieldValue}
                    blankEntryFn={() => ({ propertyId: '', relationship: '' })}
                    fields={[
                      {
                        fieldType: 'select',
                        fieldLabel: 'Property Id',
                        placeholder: '- Select Property Id -',
                        fieldName: 'propertyId',
                        options: propertyOptionList
                      },
                      {
                        fieldType: 'select',
                        fieldLabel: 'Relationship',
                        placeholder: '- Select Relationship -',
                        fieldName: 'relationship',
                        options: [
                          { value: 'child', text: 'Child' },
                          { value: 'parent', text: 'Parent' },
                        ]
                      },
                    ]} />
                </>
              )}

              <h3>Pricing</h3>
              <Row>
                <Col xs={12} md={3}>
                  <TextInputField
                    fieldType="number"
                    fieldLabel="Base Rate"
                    required={true}
                    placeholder="Base Rate"
                    fieldName={`pricing.baseRate`} />
                </Col>
                <Col xs={12} md={3}>
                  <TextInputField
                    fieldType="number"
                    fieldLabel="Deposit"
                    required={true}
                    placeholder="Deposit"
                    fieldName={`pricing.deposit`} />
                </Col>
                <Col xs={12} md={3}>
                  <TextInputField
                    fieldType="number"
                    fieldLabel="Cleaning"
                    required={true}
                    placeholder="Cleaning"
                    fieldName={`pricing.cleaning`} />
                </Col>
                <Col xs={12} md={3}>
                  <TextInputField
                    fieldType="number"
                    fieldLabel="Tax %"
                    required={true}
                    placeholder="Tax %"
                    fieldName={`pricing.tax`} />
                </Col>
              </Row>

              {/* <h4>Extra Fees</h4> */}
              <Collapsible title="Extra Fees">
                <div>
                  <label htmlFor="Extra Fees">Extra Fees</label><br />
                  <ArrayFieldInput
                    fieldName={'pricing.extraFees'}
                    values={values.pricing.extraFees}
                    setFieldValue={setFieldValue}
                    blankEntryFn={() => ({ name: '', amount: 0, frequency: 'once' })}
                    fields={extraFeesFieldValues} />
                </div>
              </Collapsible>

              <h3>Virtual Tour</h3>
              <TextInputField
                fieldType="text"
                fieldLabel="Virtual Tour"
                placeholder="Virtual Tour"
                fieldName={`virtualTour`} />

              {/* <h3>Authorized Users</h3> */}
              <Collapsible
                title="Authorized Users"
                TitleTag="h3"
              >
                <div>
                  <label htmlFor="Authorized Users">Authorized Users</label><br />
                  <ArrayFieldInput
                    fieldName={'authorizedUsers'}
                    values={values.authorizedUsers}
                    setFieldValue={setFieldValue}
                    blankEntryFn={() => ({ email: '', role: '' })}
                    fields={authorizedUsersFieldValues()} />
                </div>
              </Collapsible>

              <h3>Public Property</h3>
              <CheckBoxInputField
                fieldLabel={'Display this listing to the public'}
                fieldName={'public'} />

              <h3>Services</h3>
              <CheckBoxInputField
                fieldLabel={'This property requires cleaning services'}
                fieldName={'services.cleaning'} />

              <CheckBoxInputField
                fieldLabel={'This property requires booking guest info services'}
                fieldName={'services.bookingGuestInfo'} />

              <TextInputField
                fieldType="text"
                fieldLabel="Booking Guest Email"
                placeholder="checkerinner@checkin.com"
                fieldName={`services.bookingGuestEmail`} />

              <hr />

              {/* <h3>Linked Services</h3> */}
              <Collapsible
                title="Linked Services"
                TitleTag="h3"
              >
                <div>
                  <label htmlFor="Linked Services">Linked Services</label><br />
                  {
                    eligibleForFontainePortal && (
                      <Button
                        className={"me-2 mb-2"}
                        variant={"secondary"}
                        size={"sm"}
                        onClick={() => {
                          setFieldValue(
                            'linkedServices',
                            [
                              ...values.linkedServices,
                              fontainePortalLinkedService()
                            ]
                          )
                        }}>
                        + Fontaine Portal
                      </Button>
                    )
                  }

                  {/* early check in button and late check out button */}

                  {eligibleForEarlyCheckIn && (
                    <Button
                      className={"me-2 mb-2"}
                      variant={"secondary"}
                      size={"sm"}
                      onClick={() => {
                        setFieldValue(
                          'linkedServices',
                          [
                            ...values.linkedServices,
                            earlyCheckInLinkedService()
                          ]
                        )
                      }}>
                      + Early Check In
                    </Button>
                  )}

                  {eligibleForLateCheckOut && (
                    <Button
                      className={"me-2 mb-2"}
                      variant={"secondary"}
                      size={"sm"}
                      onClick={() => {
                        setFieldValue(
                          'linkedServices',
                          [
                            ...values.linkedServices,
                            lateCheckOutLinkedService()
                          ]
                        )
                      }}>
                      + Late Check Out
                    </Button>
                  )}

                  {/* Remote lock Service Button */}
                  {eligibleForRemoteLock && (
                    <Button
                      className={"me-2 mb-2"}
                      variant={"secondary"}
                      size={"sm"}
                      onClick={() => {
                        setFieldValue(
                          'linkedServices',
                          [
                            ...values.linkedServices,
                            remoteLockLinkedService()
                          ]
                        )
                      }}>
                      + Remote Lock
                    </Button>
                  )}

                  {/* Parking sevice Button */}
                  {eligibleForParking && (
                    <Button
                      className={"me-2 mb-2"}
                      variant={"secondary"}
                      size={"sm"}
                      onClick={() => {
                        setFieldValue(
                          'linkedServices',
                          [
                            ...values.linkedServices,
                            parkingLinkedService()
                          ]
                        )
                      }}>
                      + Parking
                    </Button>
                  )}

                  <ArrayFieldInput
                    fieldName={'linkedServices'}
                    values={values.linkedServices}
                    setFieldValue={setFieldValue}
                    blankEntryFn={() => ({ linkedService: '', linkedServiceId: '' })}
                    fields={linkedServicesFieldValues} />
                </div>
              </Collapsible>


              <DateInputField
                fieldLabel={'Date property was added'}
                placeholder={'mm/dd/yyyy'}
                fieldName={'created'} />

              {/*Props: {JSON.stringify(props)}
            <br />*/}
              Errors: {JSON.stringify(errors)}
              <br />
              <Button className="mb-3" type="submit" disabled={Object.keys(errors).length > 0 || isSubmitting || disabled || uploadingImage}>
                Submit
              </Button>
            </Form>
          )
        }}
      </Formik>
    </div>
  )
}

const fontainePortalLinkedService = () => {
  return {
    linkedService: 'fontainePortal',
    linkedServiceId: JSON.stringify({
      "username": "USERNAME",
      "password": "PASSWORD",
      "email": "EMAIL",
      "phone": "PHONE",
      "skipValidation": true
    })
  }
}

const earlyCheckInLinkedService = () => {
  return {
    linkedService: 'earlyCheckIn',
    linkedServiceId: JSON.stringify({
      enabled: true,
      options: []
    })
  }
}

const lateCheckOutLinkedService = () => {
  return {
    linkedService: 'lateCheckOut',
    linkedServiceId: JSON.stringify({
      enabled: true,
      options: []
    })
  }
}

const remoteLockLinkedService = () => {
  return {
    linkedService: 'remoteLock',
    linkedServiceId: JSON.stringify({
      deviceId: 'DEVICE_ID',
      deviceName: 'DEVICE_NAME',
      deviceType: 'locks'
    })
  }
}

const parkingLinkedService = () => {
  return {
    linkedService: 'parking',
    linkedServiceId: JSON.stringify({
      parkingType: 'PARKING_TYPE',
      parkingGroups: ['PARKING_LOCATION'],
      parkingCost: 35,
    })
  }
}