import { PurchaseEntryType } from "../../../Types/purchaseTypes"
import { Form, Formik } from "formik"
import { TextInputField } from "../../FormFields/TextInput/TextInputField"
import { DateInputField } from "../../FormFields/DateInput/DateInputField"
import { CheckBoxInputField } from "../../FormFields/CheckBoxInput/CheckBoxInputField"
import { ArrayFieldInput } from "../../FormFields/ArrayInput/ArrayFieldInput"
import { Button, Col, Row } from "react-bootstrap"
import { PhoneNumberInputField } from "../../FormFields/PhoneNumberInput/PhoneNumberInputField"

const generateBlankPurchase = () => ({
  transactionId: '',
  paymentIntent: '',
  paymentStatus: '',
  sessionStatus: '',
  merchant: '',
  dateCreated: new Date(),
  emailSent: false,
  prepaid: false,
  products: [],
  // discounts: [],
  // billingInfo
  // shippingPrice
  // deliveryOption
  // shippingAddress
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  referenceNumber: '',
  iAgree: false,
})

export default function PurchaseForm(props: {
  disabled: boolean,
  onSubmit: (v: any) => Promise<any>,
  initialValues?: Partial<PurchaseEntryType>,
}) {
  const {
    disabled,
    onSubmit,
    initialValues,
  } = props

  return (
    <div className="purchase-form">
      <Formik
        initialValues={{
          ...generateBlankPurchase(),
          ...(initialValues ? initialValues : {})
        }}
        validate={values => {
          const errors: any = {};
          // fields.forEach(field => {
          //     const fieldVal = checkVal(values, field.fieldName)
          //     if (field.required && (!fieldVal || fieldVal === '')) {
          //         writeVal(errors, field.fieldName, 'Required')
          //     }
          // })
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          if (onSubmit) {
            try {
              onSubmit({ ...values })
            } catch (err) {
              // custom error handler
            }
          }
          setSubmitting(false)
        }}
      >
        {({ values, isSubmitting, errors, touched, setFieldValue, handleChange, setValues }) => (
          <Form>
            <TextInputField
              fieldType="text"
              fieldLabel="Transaction ID"
              placeholder="Transaction ID"
              fieldName="transactionId"
            />

            <TextInputField
              fieldType="text"
              fieldLabel="Payment Intent"
              placeholder="Payment Intent"
              fieldName="paymentIntent"
            />

            <TextInputField
              fieldType="text"
              fieldLabel="Payment Status"
              placeholder="Payment Status"
              fieldName="paymentStatus"
            />

            <TextInputField
              fieldType="text"
              fieldLabel="Session Status"
              placeholder="Session Status"
              fieldName="sessionStatus"
            />

            <TextInputField
              fieldType="text"
              fieldLabel="Merchant"
              placeholder="Merchant"
              fieldName="merchant"
            />

            <DateInputField
              fieldLabel="Date Created"
              placeholder="Date Created"
              fieldName="dateCreated"
            />

            <CheckBoxInputField
              fieldLabel="Email Sent"
              fieldName="emailSent"
            />

            <CheckBoxInputField
              fieldLabel="Prepaid"
              fieldName="prepaid"
            />
            <h3>Products</h3>
            <ArrayFieldInput
              fieldName="products"
              values={values.products}
              setFieldValue={setFieldValue}
              blankEntryFn={() => ({
                productId: '',
                productType: '',
                productName: '',
                productPrice: 0,
                productQuantity: 0,
              })}
              fields={[
                {
                  fieldType: 'text',
                  fieldLabel: 'Product ID',
                  placeholder: 'Product ID',
                  fieldName: 'productId',
                },
                {
                  fieldType: 'text',
                  fieldLabel: 'Product Type',
                  placeholder: 'Product Type',
                  fieldName: 'productType',
                },
                {
                  fieldType: 'text',
                  fieldLabel: 'Product Name',
                  placeholder: 'Product Name',
                  fieldName: 'productName',
                },
                {
                  fieldType: 'number',
                  fieldLabel: 'Product Price',
                  placeholder: 'Product Price',
                  fieldName: 'productPrice',
                },
                {
                  fieldType: 'number',
                  fieldLabel: 'Product Quantity',
                  placeholder: 'Product Quantity',
                  fieldName: 'productQuantity',
                },
              ]}
            />

            <h3>Purchaser Details</h3>

            <Row>
              <Col>
                <TextInputField
                  fieldType="text"
                  fieldLabel="First Name"
                  placeholder="First Name"
                  fieldName="firstName"
                />
              </Col>
              <Col>
                <TextInputField
                  fieldType="text"
                  fieldLabel="Last Name"
                  placeholder="Last Name"
                  fieldName="lastName"
                />
              </Col>
            </Row>

            <Row>
              <Col>
                <TextInputField
                  fieldType="text"
                  fieldLabel="Email"
                  placeholder="Email"
                  fieldName="email"
                />
              </Col>
              <Col>
                <PhoneNumberInputField
                  fieldLabel="Phone Number"
                  placeholder="Phone Number"
                  fieldName="phoneNumber"
                />
              </Col>
            </Row>



            <TextInputField
              fieldType="text"
              fieldLabel="Reference Number"
              placeholder="Reference Number"
              fieldName="referenceNumber"
            />

            <CheckBoxInputField
              fieldLabel="I Agree"
              fieldName="iAgree"
            />

            <hr />
            <div className="mb-3">
              Errors: {JSON.stringify(errors)}
            </div>
            {/* {uploadingImage && (<><br />Uploading image please wait...</>)} */}

            <Button className="mb-3" type="submit" disabled={Object.keys(errors).length > 0 || isSubmitting || disabled}>
              Submit
            </Button>


          </Form>)}
      </Formik>
    </div>
  )
}
