import { Button, Modal } from "react-bootstrap";
import QuickForm from "../../Forms/QuickForm/QuickForm";
import timeService from "../../../services/time/timeService";
import { useState } from "react";
import { useAppDispatch } from "../../../redux/store";
import { showError } from "../../../redux/counter/counterSlice";
import { useEditBookingMutation } from "../../../services/bClientApi";

export default function BookingModal(props: {
    active: boolean,
    booking: any
    clearBookingModal: () => void,
    bookingModalonSubmit: (values: any) => void
}) {
    const {
        active,
        booking,
        clearBookingModal,
        bookingModalonSubmit } = props
    return (
        <Modal show={active} onHide={clearBookingModal}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Booking</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {booking && (
                    <>
                        <b>Booking ID:</b> {booking._id} <br />
                        <b>Name: </b> {booking.contactInfo.firstName} {booking.contactInfo.lastName} <br />
                        {/* <b>Check In: </b> {timeService.getFormattedTime(editBookingModal.booking.checkIn)} <br /> */}
                        {/* <b>Check Out: </b> {timeService.getFormattedTime(editBookingModal.booking.checkOut)} <br /> */}
                        <QuickForm
                            key={`${booking._id}}`}
                            initialData={{
                                checkIn: new Date(booking.checkIn),
                                checkOut: new Date(booking.checkOut),
                            }}
                            fields={[
                                {
                                    fieldName: 'checkIn',
                                    fieldLabel: 'Check In',
                                    fieldType: 'date',
                                    required: true,
                                    layout: {
                                        xs: 12,
                                        md: 6,
                                    },
                                },
                                {
                                    fieldName: 'checkIn',
                                    fieldLabel: 'Check In Time',
                                    fieldType: 'date',
                                    required: true,
                                    extra: {
                                        showTimeSelect: true,
                                        showTimeSelectOnly: true,
                                        // timeIntervals: 15,
                                        dateFormat: "h:mm aa",
                                        showTimeCaption: false
                                    },
                                    layout: {
                                        xs: 12,
                                        md: 6,
                                    },
                                },
                                {
                                    fieldName: 'checkOut',
                                    fieldLabel: 'Check Out',
                                    fieldType: 'date',
                                    required: true,
                                    layout: {
                                        xs: 12,
                                        md: 6,
                                    },
                                },
                                {
                                    fieldName: 'checkOut',
                                    fieldLabel: 'Check Out Time',
                                    fieldType: 'date',
                                    required: true,
                                    extra: {
                                        showTimeSelect: true,
                                        showTimeSelectOnly: true,
                                        // timeIntervals: 15,
                                        dateFormat: "h:mm aa",
                                        showTimeCaption: false
                                    },
                                    layout: {
                                        xs: 12,
                                        md: 6,
                                    },
                                },
                            ]}
                            onSubmit={bookingModalonSubmit}
                        />
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={clearBookingModal}>
                    Close
                </Button>
                {/* <Button variant="primary" onClick={clearBookingModal}>
                Save Changes
            </Button> */}
            </Modal.Footer>
        </Modal>
    )
}

export function useBookingModal() {
    const [editBookingModal, setEditBookingModal] = useState<any>({
        active: false,
        booking: null,
    })

    const [
        editBooking,
    ] = useEditBookingMutation()

    const dispatch = useAppDispatch()

    const clearBookingModal = () => {
        setEditBookingModal({
            active: false,
            booking: null,
        })
    }

    const openBookingModal = (b: any) => {
        setEditBookingModal({
            active: true,
            booking: { ...b },
        })
    }

    const onEditClick = (b: any) => {
        openBookingModal(b)
    }

    const onCheckOutClick = (b: any) => {
        const updatedB: any = {
            ...b
        }
        updatedB.checkOut = timeService.getCurrentTime().startOf('hour').toJSDate()
        openBookingModal(updatedB)
    }

    const bookingModalonSubmit = async (b: any) => {
        const editedBooking = await editBooking({
            bookingId: editBookingModal.booking._id,
            bookingInfo: {
                checkIn: b.checkIn,
                checkOut: b.checkOut,
            }
        }).unwrap()
        if (editedBooking && editedBooking._id) {
            clearBookingModal()
        } else {
            dispatch(showError('Error updating booking'))
        }
    }

    return {
        editBookingModal,
        clearBookingModal,
        onEditClick,
        onCheckOutClick,
        bookingModalonSubmit
    }
}