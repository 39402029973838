import { useMemo, useState } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import CalendarBookings from "../../../Components/Bookings/CalendarBookings/CalendarBookings";
import { FieldGeneratorFieldType } from "../../../Components/FormFields/FieldGenerator/FieldGenerator";
import QuickForm from "../../../Components/Forms/QuickForm/QuickForm";
import { textBooleanHandler } from "../../../Helpers/ValueHandlers/boolHandler";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useBookingsByPropertyMemo from "../../../Memos/Bookings/UseBookingsByPropertyMemo";
import timeService from "../../../services/time/timeService";
import { PropertyBookingEntry } from "../../../Types/bookingTypes";
import { FONTAINE_PUPPET_CHECK_AVAILABILITY } from "../../../Config/config";
import { Link } from "react-router-dom";
import AccessLinkButton from "../../../Components/Buttons/AccessLinkButton";
import Collapsible from "../../../Components/Collapsible/Collapsible";
import canAccess from "../../../services/accessService/accessService";
import BookingPreview from "../Booking/BookingPreview";
import { hasChildren } from "../../../Helpers/Property/hasChildren";
import { showError, showSuccess } from "../../../redux/counter/counterSlice";
import { useAppDispatch } from "../../../redux/store";
import { useGetBookingsQuery, useGetDateBlocksQuery } from "../../../services/bClientApi";

export default function UpcomingBookingsPage() {
    const { myProperties, publicPropertyIdKeys } = useMyProperties()
    const startOfToday = timeService.getCurrentTime().set({ hour: 15 }).toMillis()
    const queryEndDate = timeService.getCurrentTime().plus({ days: 2 }).set({ hour: 10 }).toMillis()
    const [activeQuery, setActiveQuery] = useState<any>({
        query: {
            checkIn: startOfToday,
            checkOut: queryEndDate,
            bookingStatus: ['new', 'pending', 'confirmed'],
            propertyId: publicPropertyIdKeys,
        },
        limit: 500,
        page: 1
    })

    const [checkAvailabilityLoading, setCheckAvailabilityLoading] = useState<boolean>(false)

    const [bookingPreview, setBookingPreview] = useState<Partial<any>>({ booking: {}, nights: 0, earnings: 0 })

    const dispatch = useAppDispatch()

    const {
        data,
        error
    } = useGetBookingsQuery({
        bookingsQuery: {
            ...activeQuery.query,
            propertyId: publicPropertyIdKeys
        },
        pagination: {
            page: 1,
            limit: 500,
            all: true,
        }
    }, { skip: publicPropertyIdKeys.length === 0 })

    const bookingsList = data?.docs || []

    // begin block logic
    const [activeBlocksQuery, setActiveBlocksQuery] = useState<any>({
        query: {
            checkIn: startOfToday,
            checkOut: queryEndDate,
            status: ['new', 'pending', 'confirmed'],
            propertyId: publicPropertyIdKeys,
        },
        limit: 500,
        page: 1
    })

    const {
        data: dateBlocksData,
        // error: dateBlocksError
    } = useGetDateBlocksQuery({
        dateBlocksQuery: activeBlocksQuery.query,
        pagination: {
            page: 1,
            limit: 500,
            all: true,
        }
    }, { skip: publicPropertyIdKeys.length === 0 })

    const dateBlocksList = dateBlocksData?.docs || []


    const [propertyFilters, setPropertyFilters] = useState<any>({
        quickFilter: '',
        hideUnavailable: false,
    })

    const [expandAllProperties, setExpandAllProperties] = useState<boolean>(false)
    const [openProperties, setOpenProperties] = useState<any>({})

    // add the regular bookings to doubles and mark them
    const bookingsByProperty = useBookingsByPropertyMemo(bookingsList ? bookingsList : [], {
        propertyData: myProperties,
        combineMulti: true
    })

    // const bookingRef = useListToRefMemo(bookingsList ? bookingsList : [])

    const selectedPropertiesWithBookings = useMemo(() => {
        const bookingPropertyRef: any = {}
        bookingsByProperty.forEach((x: { propertyId: string, bookings: PropertyBookingEntry[] }) => {
            bookingPropertyRef[x.propertyId] = x.bookings
        })

        return Object.keys(myProperties).map((x: string) => {
            const property = myProperties[x]
            const bookings = bookingPropertyRef[x]
            // map in the child bookings here

            let blocksInRange = []
            if (dateBlocksList) {
                blocksInRange = dateBlocksList.filter((y: any) => {
                    return y.propertyId === x
                })
            }

            return {
                ...property,
                propertyId: x,
                bookings: bookings,
                blocks: blocksInRange
            }
        }).sort((x, y) => {
            return x.title < y.title ? -1 : 1
        }).filter(x => {
            if (!x.public) { return false }
            // if (propertyFilters.hideUnavailable === true && x && x.bookings) {
            //     return x.bookings.length === 0
            // }

            let showProperty = true

            if (propertyFilters.quickFilter) {
                // check if the first character is ! and if so, check if the property title does not include the rest of the string
                if (propertyFilters.quickFilter[0] === '!') {
                    if (x.title.toLowerCase().includes(propertyFilters.quickFilter.slice(1).toLowerCase())) {
                        showProperty = false
                    }
                } else {
                    if (!x.title.toLowerCase().includes(propertyFilters.quickFilter.toLowerCase())) {
                        showProperty = false
                    }
                }
            }
            if (propertyFilters.bedrooms && propertyFilters.bedrooms > 0) {
                if (!(x?.about?.bedrooms === +propertyFilters.bedrooms)) {
                    showProperty = false
                }
            }
            if (propertyFilters.bathrooms && propertyFilters.bathrooms > 0) {
                if (!(x?.about?.bathrooms >= +propertyFilters.bathrooms)) {
                    showProperty = false
                }
            }

            return showProperty
        })
    }, [bookingsByProperty, myProperties, propertyFilters])

    const handleUpcomingBookingsFormSubmit = async (data: any) => {
        const newQuery = {
            ...activeQuery,
            query: {
                ...activeQuery.query,
            }
        }
        const newBlockQuery = {
            ...activeBlocksQuery,
            query: {
                ...activeBlocksQuery.query,
            }
        }
        if (data.checkIn) {
            newQuery.query.checkIn = timeService.setDateTime(data.checkIn, { hour: 15 })?.getTime()
            newBlockQuery.query.checkIn = timeService.setDateTime(data.checkIn, { hour: 15 })?.getTime()
        } else {
            newQuery.query.checkIn = null
            newBlockQuery.query.checkIn = null
        }
        if (data.checkOut) {
            newQuery.query.checkOut = timeService.setDateTime(data.checkOut, { hour: 10 })?.getTime()
            newBlockQuery.query.checkOut = timeService.setDateTime(data.checkOut, { hour: 10 })?.getTime()
        } else {
            newQuery.query.checkOut = null
            newBlockQuery.query.checkOut = null
        }
        setActiveQuery(newQuery)
        setActiveBlocksQuery(newBlockQuery)

        // setPropertyFilters({
        //     quickFilter: data.quickFilter ? data.quickFilter : '',
        //     hideUnavailable: data.hideUnavailable ? data.hideUnavailable : false,
        // })
    }

    const checkFontaineAvailability = async (propertyId: string) => {
        const propertyInfo = myProperties[propertyId]

        const fontainePayload: any = {
            unit: propertyInfo.address.address2,
            checkIn: activeQuery.query.checkIn,
            checkOut: activeQuery.query.checkOut,
        }

        if (propertyInfo.linkedServices && propertyInfo.linkedServices.length > 0) {
            const fontaineService = propertyInfo.linkedServices.find(x => x.linkedService === 'fontainePortal')
            if (fontaineService) {
                try {
                    const fontainePortalCreds = JSON.parse(fontaineService.linkedServiceId)
                    fontainePayload.username = fontainePortalCreds.username
                    fontainePayload.password = fontainePortalCreds.password
                } catch (e) {
                    console.log('error parsing fontaine creds')
                    console.log(e)
                }
            }
        }
        setCheckAvailabilityLoading(true)
        const fontaineResponse = await fetch(FONTAINE_PUPPET_CHECK_AVAILABILITY, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(fontainePayload)
            // body: fontainePayload
        }).then((res) => {
            return res.json()
        }).catch((err) => {
            return {
                error: err
            }
        })

        if (fontaineResponse.error) {
            dispatch(showError(fontaineResponse.error))
        } else {
            dispatch(showSuccess('Request has been sent to puppet bookings will begin processing'))
        }

        setCheckAvailabilityLoading(false)
    }

    const nights = timeService.calculateNights(activeQuery.query.checkIn, activeQuery.query.checkOut)
    const uniqueProperties = selectedPropertiesWithBookings.filter(x => hasChildren(x) === false)
    // const uniqueProperties = selectedPropertiesWithBookings.filter(x => x.title.toLowerCase().indexOf('double') === -1)
    const bookedProperties = uniqueProperties.filter(x => x.bookings?.length > 0).length
    const emptyProperties = uniqueProperties.length - bookedProperties
    const bookingCommitment = ((bookedProperties / uniqueProperties.length) * 100).toFixed(2)

    const directCheckInMillis = timeService.setDateTime(activeQuery.query.checkIn, { hour: 16 })?.getTime()
    const directCheckOutMillis = timeService.setDateTime(activeQuery.query.checkOut, { hour: 11 })?.getTime()

    return (
        <Container>
            <Row>
                <Col>
                    <h1>Upcoming Bookings</h1>
                    <hr />
                    {error && (<>{JSON.stringify(error)}<br /></>)}

                    <div className="mb-3">
                        <b>Nights:</b> {nights} <br />
                        <b>Booked Properties:</b> {bookedProperties} <br />
                        <b>Empty Properties:</b> {emptyProperties} <br />
                        <b>Unique Properties:</b> {uniqueProperties.length} <br />
                        <b>Booking Commitment:</b> {bookingCommitment}% <br />
                    </div>


                    {/* {Object.keys(myProperties).length} <br />
                    {bookingsByProperty.length} <br />
                    {bookingsList?.length} <br />
                    {Object.keys(bookingRef).length} <br /> */}

                    <QuickForm
                        initialData={{
                            dateRange: null,
                            checkIn: startOfToday,
                            checkOut: queryEndDate,
                            quickFilter: '',
                            hideUnavailable: false,
                        }}
                        fields={upcomingBookingsFormFields}
                        onChange={(data: any) => {
                            const fieldName = data.target.name
                            const fieldValue = data.target.value
                            if (quickFilterFields[fieldName]) {
                                const newFilters = {
                                    ...propertyFilters,
                                    [fieldName]: quickFilterFields[fieldName].handler ? quickFilterFields[fieldName].handler(fieldValue) : fieldValue,
                                }
                                setPropertyFilters(newFilters)
                            }

                        }}
                        onSubmit={handleUpcomingBookingsFormSubmit} />

                    <hr />
                    <Button
                        onClick={() => {
                            setExpandAllProperties(!expandAllProperties)
                        }}
                        variant={'secondary'}>
                        {expandAllProperties ? 'Collapse All' : 'Expand All'}
                    </Button>
                    <hr />


                    {selectedPropertiesWithBookings.filter((x) => {
                        if (propertyFilters.hideUnavailable === true && x && x.bookings) {
                            return x.bookings.length === 0
                        }
                        return true
                    }).map((property, idx: number) => {
                        let airbnbLink: string = ''
                        let editAirbnbLink: string = ''

                        let calendarLink: string = ''
                        const benichayBrothersLink = `https://www.benichaybrothers.com/property/${property._id}?checkIn=${directCheckInMillis}&checkOut=${directCheckOutMillis}`
                        // const benichayBrothersLink = `https://www.benichaybrothers.com/property/${property._id}?checkIn=${activeQuery.query.checkIn}&checkOut=${activeQuery.query.checkOut}`
                        if (property.listed) {
                            property.listed.forEach((x: any) => {
                                if (airbnbLink) return

                                if (x.source === 'airbnb') {
                                    airbnbLink = x.url
                                    calendarLink = x.url.replace('rooms', 'multicalendar')

                                    const airbnbId = x.url.split('/').pop()
                                    editAirbnbLink = `https://www.airbnb.com/hosting/listings/editor/${airbnbId}`
                                }
                            })
                        }

                        const isFontaine = property.title.toLowerCase().indexOf('fontaine') > -1
                        // const isParentProperty = getChildProperties(myProperties, property._id).length > 0

                        const financialsPageLink = `/financials-property/${property._id}`
                        const pricingPageLink = `/handle-property-prices/${property._id}`

                        const hasBlocks = property.blocks && property.blocks.length > 0


                        return (
                            <div key={`${property.propertyId}-${idx}`}>

                                <Collapsible
                                    initialValue={expandAllProperties}
                                    key={expandAllProperties ? `expand-all-${idx}` : `collapse-${idx}`}
                                    title={myProperties[property.propertyId]?.title}
                                    TitleTag={'h3'}
                                    titleClass={`booking-entry-property-title ${property.bookings?.length > 0 ? 'text-danger' : 'text-success'}`}
                                >
                                    <>
                                        {airbnbLink && (
                                            <div className="mb-3 small">
                                                Listing: <a href={airbnbLink} target="_blank" rel="noreferrer">
                                                    {airbnbLink}
                                                </a>&nbsp;
                                                {canAccess('property', 'edit-airbnb') && (
                                                    <a href={editAirbnbLink} className="text-decoration-none" target="_blank" rel="noreferrer">
                                                        ✏️
                                                    </a>
                                                )}
                                            </div>
                                        )}
                                        {calendarLink && (
                                            <div className="mb-3 small">
                                                Calendar: <a href={calendarLink} target="_blank" rel="noreferrer">
                                                    {calendarLink}
                                                </a>
                                            </div>
                                        )}
                                        {benichayBrothersLink && (
                                            <div className="mb-3 small">
                                                Site Link: <a href={benichayBrothersLink} target="_blank" rel="noreferrer">
                                                    {benichayBrothersLink}
                                                </a>
                                            </div>
                                        )}

                                        {benichayBrothersLink && canAccess('property', 'direct-offer') && (
                                            <div className="mb-3 small">
                                                Direct Offer Site Link: <a href={`${benichayBrothersLink}&directOffer=${Date.now()}`} target="_blank" rel="noreferrer">
                                                    {benichayBrothersLink}&directOffer={Date.now()}
                                                </a>
                                            </div>
                                        )}


                                        {financialsPageLink && (
                                            <div className="mb-3 small">
                                                <AccessLinkButton
                                                    action={'financials-property'}
                                                    color={'secondary'}
                                                    size={'sm'}
                                                    text={'Financials'}
                                                    to={financialsPageLink}
                                                />
                                                <AccessLinkButton
                                                    action={'handle-property-prices'}
                                                    color={'secondary'}
                                                    size={'sm'}
                                                    text={'Pricing'}
                                                    to={pricingPageLink}
                                                />
                                                <AccessLinkButton
                                                    action={'add-booking'}
                                                    color={'secondary'}
                                                    size={'sm'}
                                                    text={'Booking⚡'}
                                                    to={`/add-booking?query=${JSON.stringify({
                                                        "propertyId": "5d56d4ee99b6ff0015269a0c",
                                                        "checkIn": timeService.createLuxonDate(activeQuery.query.checkIn).set({
                                                            hour: 16,
                                                            minute: 0,
                                                            second: 0,
                                                            millisecond: 0
                                                        }).toMillis(),
                                                        "checkOut": timeService.createLuxonDate(activeQuery.query.checkOut).set({
                                                            hour: 11,
                                                            minute: 0,
                                                            second: 0,
                                                            millisecond: 0
                                                        }).toMillis(),
                                                        "quickBooking": 1
                                                    })}`}
                                                />
                                                {isFontaine && (
                                                    <AccessLinkButton
                                                        action={'add-date-block'}
                                                        color={'secondary'}
                                                        size={'sm'}
                                                        text={'+ Block'}
                                                        to={`/add-date-block?query=${JSON.stringify({
                                                            "propertyId": property._id,
                                                            "checkIn": timeService.createLuxonDate(activeQuery.query.checkIn).set({
                                                                hour: 16,
                                                                minute: 0,
                                                                second: 0,
                                                                millisecond: 0
                                                            }).toMillis(),
                                                            "checkOut": timeService.createLuxonDate(activeQuery.query.checkOut).set({
                                                                hour: 11,
                                                                minute: 0,
                                                                second: 0,
                                                                millisecond: 0
                                                            }).toMillis(),
                                                        })}`}
                                                    />
                                                )}
                                            </div>
                                        )}
                                        {canAccess('routes', 'fontaine-tools') && isFontaine && (
                                            <div className="mb-3 small">
                                                <Button
                                                    size={'sm'}
                                                    disabled={checkAvailabilityLoading}
                                                    variant={'dark'}
                                                    onClick={() => {
                                                        checkFontaineAvailability(property.propertyId)
                                                    }}
                                                >
                                                    Check Availability
                                                </Button> {checkAvailabilityLoading ? 'Loading...' : ''}
                                            </div>
                                        )}

                                        {canAccess('routes', 'date-blocks') && hasBlocks && (
                                            <div className="mb-3 small">
                                                <b>Blocks:</b> {property.blocks.length} <br />
                                                <div className="d-flex gap-2 flex-wrap my-1">
                                                    {property.blocks.map((block: any, idx: number) => (
                                                        <div key={idx} className={`p-2 border rounded me-1 mb-1 position-relative ${!block.confirmationCode ? 'bg-danger text-light' : ''}`}>
                                                            {block.firstName} {block.lastName} <br />
                                                            {timeService.createLuxonDate(block.checkIn).toFormat('MM/dd')} - {timeService.createLuxonDate(block.checkOut).toFormat('MM/dd/yyyy')} <br />
                                                            {block.status} / {block.confirmationCode ? block.confirmationCode : 'UNCONFIRMED'} <br />
                                                            {canAccess('routes', 'edit-date-block') && (
                                                                <Link
                                                                    className="text-decoration-none position-absolute top-0 end-0"
                                                                    to={`/edit-date-block/${block._id}`}>
                                                                    ✏️
                                                                </Link>
                                                            )}
                                                        </div>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        <div className="booking-entry-collapse-wrapper">
                                            <CalendarBookings
                                                startDate={activeQuery.query.checkIn}
                                                endDate={activeQuery.query.checkOut}
                                                onView={canAccess('routes', 'booking') ? (booking: { raw: PropertyBookingEntry }) => {
                                                    const nights = timeService.calculateNights(booking.raw.checkIn, booking.raw.checkOut)
                                                    const earnings = nights * (booking.raw.pricePerNight || 0)
                                                    setBookingPreview({
                                                        booking: booking.raw ? booking.raw : booking,
                                                        nights: nights,
                                                        earnings: earnings
                                                    })
                                                } : undefined}
                                                blocks={property.blocks ? property.blocks : []}
                                                bookings={property.bookings ? property.bookings : []} />
                                        </div>
                                    </>
                                </Collapsible>
                                <hr />
                            </div>
                        )
                    })}
                </Col>
            </Row>

            <BookingPreview
                booking={bookingPreview.booking}
                onClose={() => {
                    setBookingPreview({
                        booking: {},
                    })
                }}
            />
        </Container>
    )
}

const quickFilterFields: any = {
    quickFilter: 1,
    bedrooms: 1,
    bathrooms: 1,
    hideUnavailable: {
        handler: textBooleanHandler
    },
}

const upcomingBookingsFormFields: FieldGeneratorFieldType[] = [
    {
        fieldName: 'dateRange',
        fieldLabel: 'Date Range',
        fieldType: 'dateRange',
        placeholder: 'mm/dd/yyyy - mm/dd/yyyy',
        extra: {
            startDate: 'checkIn',
            endDate: 'checkOut',
        }
    },
    {
        fieldName: 'checkIn',
        fieldLabel: 'Check In',
        fieldType: 'date',
        required: true,
        hidden: true,
    },
    {
        fieldName: 'checkOut',
        fieldLabel: 'Check Out',
        fieldType: 'date',
        required: true,
        hidden: true,
    },
    {
        fieldName: 'quickFilter',
        fieldLabel: 'Quick Filter',
        fieldType: 'text',
    },
    {
        fieldName: 'bedrooms',
        fieldLabel: 'Bedrooms',
        fieldType: 'number',
        layout: {
            xs: 12,
            md: 6
        }
    },
    {
        fieldName: 'bathrooms',
        fieldLabel: 'Bathrooms',
        fieldType: 'number',
        layout: {
            xs: 12,
            md: 6
        }
    },
    {
        fieldName: 'hideUnavailable',
        fieldLabel: 'Hide Unavailable',
        fieldType: 'checkbox',
        required: false
    }
]