import { Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { EditUserForm } from "../../../Components/Forms/EditUserForm/EditUserForm";
import { accountSelector } from "../../../redux/account/accountSlice";
import { useAppDispatch } from "../../../redux/store";
import { showError } from "../../../redux/counter/counterSlice";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";
import { useEditUserMutation, useGetUserInfoQuery } from "../../../services/bClientApi";
import { navigateTo } from "../../../redux/router/routerActions";

export default function EditUserPage() {

  const [
    editUserInfo,
    {
      error: editError,
      isLoading: editLoading,
    }
  ] = useEditUserMutation()

  const accountInfo = useSelector(accountSelector)
  const { userId = '' } = useParams()
  const dispatch = useAppDispatch()

  const {
    data,
    error,
    isLoading
  } = useGetUserInfoQuery(userId, { skip: !userId })
  const { 
    _id, 
    email, 
    firstName, 
    lastName, 
    phone,
    role, 
    profilePic,
    notificationSettings = {
      whatsApp: false,
      email: false,
      sms: false,
    }
  } = data || {}

  const {
    uploadImageFn,
    // imageUploadProgress,
    // uploadImageMultiFn,
    isLoading: uploadImageLoading,
    error: uploadImageError,
  } = useUploadImage()


  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit User Page</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {uploadImageError && (<>{JSON.stringify(uploadImageError)}<br /></>)}
          {editError && (<>{JSON.stringify(editError)}<br /></>)}

          Editing User: {userId} <br />
          <br />
          Use the Form Below to Edit the User Information <br />
          <br />
          {_id === userId && !isLoading && (
            <>
              <EditUserForm
                firstName={firstName}
                lastName={lastName}
                email={email}
                phone={phone}
                role={role}
                notificationSettings={notificationSettings}
                profilePic={profilePic ? profilePic : ''}
                disabled={editLoading || uploadImageLoading}
                onSubmit={async (info: any) => {
                  if(role === 'admin' && accountInfo.role !== 'admin') {
                    dispatch(showError('You do not have permission to edit this user'))
                    return
                  }
                  if(_id) {
                    await editUserInfo({
                      userId: _id,
                      updatedUserInfo: info
                    }).unwrap()
                  }
                  dispatch(navigateTo(`/user/${_id}`))
                  return
                }}
                uploadImageFn={uploadImageFn}
              />
            </>
          )}


        </Col>
      </Row>
    </Container>
  )
}
