// export const SERVER_URL = 'https://rocko-lee.herokuapp.com';
export const SERVER_URL = 'https://brocko-lee.benichaybrothers.com';
// export const SERVER_URL = 'http://127.0.0.1:3008';
export const API_URL = `${SERVER_URL}/api`;

export const FONTAINE_PUPPET_BASE_URL = 'https://fontaine-puppet.herokuapp.com'
// export const FONTAINE_PUPPET_BASE_URL = 'http://localhost:3420'
export const FONTAINE_PUPPET_CHECK_AVAILABILITY = `${FONTAINE_PUPPET_BASE_URL}/puppet-fontaine/check-availability`
export const FONTAINE_PUPPET_CHECK_BOOKINGS = `${FONTAINE_PUPPET_BASE_URL}/puppet-fontaine/check-bookings`
export const FONTAINE_PUPPET_CHECK_BLACKOUTS = `${FONTAINE_PUPPET_BASE_URL}/puppet-fontaine/blackout-dates`
export const FONTAINE_PUPPET_VALIDATE_BOOKING = `${FONTAINE_PUPPET_BASE_URL}/puppet-fontaine/validate-bookings`

export const BENICHAY_RESERVES_BASE_URL = 'https://benichay-reserves.herokuapp.com'

// YY.MM.DD.version
export const BCLIENT_APP_VERSION = '24.12.11.1500-FSCP'

// export const FRONTEND_URL = 'http://localhost:3009'
export const FRONTEND_URL = 'https://www.benichaybrothers.com'

// CHECK IF PRIME REGEX
//  /^1?$|^(11+?)\1+$/