import { Button, Col, Container, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { PropertyForm } from "../../../Components/Forms/PropertyForm/PropertyForm";
import useMyProperties from "../../../Hooks/UseMyProperties/UseMyPropertiesHook";
import useMyPropertiesSelectValues from "../../../Hooks/UseMyProperties/UseMyPropertySelectValuesHook";
import { accountSelector } from "../../../redux/account/accountSlice";
import { navigateTo } from "../../../redux/router/routerActions";
import { useAppDispatch } from "../../../redux/store";
import { PropertyType } from "../../../Types/propertyTypes";
import StickyBar from "../../../Components/StickyBar/StickyBar";
import { useAddNewPropertyMutation, useEditPropertyMutation, useGetPropertyByIdQuery } from "../../../services/bClientApi";
import useUploadImage from "../../../Hooks/UseUploadImage/useUploadImageHook";

export default function EditPropertyPage() {
  const { email, role } = useSelector(accountSelector)
  const { 
    propertyId = ''
  } = useParams()

  const {
    data: propertyData = {},
    error,
    isLoading
  } = useGetPropertyByIdQuery(propertyId, { skip: !propertyId })

  const {
    _id = ''
  } = propertyData

  const [
    editProperty,
    {
      isLoading: isEditing,
      error: editError
    }
  ] = useEditPropertyMutation()

  const [
    addNewProperty,
    {
      isLoading: isAdding,
      error: addError
    }
  ] = useAddNewPropertyMutation()

  const {
    uploadImageFn,
    imageUploadProgress,
    uploadImageMultiFn,
    // isLoading: uploadImageLoading,
    error: uploadImageError,
  } = useUploadImage()

  const dispatch = useAppDispatch()

  const { myProperties } = useMyProperties()
  const myPropertySelectOptions = useMyPropertiesSelectValues(myProperties)

  const onSubmit = async (values: Partial<PropertyType>) => {
    if (!email || !role || !_id) {
      return
    }
    if (!values.authorizedUsers) {
      values.authorizedUsers = [{
        email: email,
        role: role
      }]
    } else if (values.authorizedUsers.filter((x) => {
      return x.email.toLowerCase() === email.toLowerCase()
    }).length === 0) {
      values.authorizedUsers.push({
        email: email,
        role: role
      })
    }
    const editedProperty: any = await editProperty({ propertyId: _id, propertyInfo: values }).unwrap()
    if (editedProperty._id) {
      dispatch(navigateTo(`/property/${editedProperty._id}`))
    }
    return editedProperty
  }

  return (
    <Container>
      <Row>
        <Col>
          <h1>Edit Property</h1>
          <hr />
          {error && (<>{JSON.stringify(error)}<br /></>)}
          {editError && (<>{JSON.stringify(editError)}<br /></>)}
          {addError && (<>{JSON.stringify(addError)}<br /></>)}
          {uploadImageError && (<>{JSON.stringify(uploadImageError)}<br /></>)}


          {email && role && _id === propertyId && propertyData && !isLoading && (
            <>
              <StickyBar>
                <h4>
                  {propertyData.title.split(' - ')[0]}
                </h4>
              </StickyBar>
              <PropertyForm
                disabled={isEditing === true || isAdding === true}
                propertyOptionList={myPropertySelectOptions}
                initialValues={propertyData}
                onSubmit={onSubmit}
                uploadImageFn={uploadImageFn}
                imageUploadProgress={imageUploadProgress}
                uploadImageMultiFn={uploadImageMultiFn}
              />

              <hr />

              <Button
                variant="secondary"
                disabled={isEditing === true || isAdding === true}
                onClick={async () => {
                  const newPropertyData = {
                    ...propertyData,
                  }
                  delete newPropertyData._id
                  newPropertyData.title = `${newPropertyData.title} (COPY)`
                  newPropertyData.authorizedUsers = newPropertyData.authorizedUsers.filter((x: { email: string, role: string }) => {
                    // remove any owner
                    return x.role !== 'owner'
                  })
                  // console.log('newPropertyData', newPropertyData)
                  const createdProperty = await addNewProperty(newPropertyData).unwrap()
                  if (createdProperty._id) {
                    dispatch(navigateTo(`/property/${createdProperty._id}`))
                  }
                }}
              >
                Copy Property
              </Button>


            </>
          )}
        </Col>
      </Row>
    </Container>
  )
}
